.App {
  background-color: #787070;
  text-align: center;
  margin-top: 50px;
}
h1 {
  color: #f1e3e3;
}
h2 {
  color: rgb(235, 243, 243);
}

input[type="number"],
select {
  padding: 8px;
  font-size: 16px;
  margin: 10px;
  border-radius: 5px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #177ce8;
  color: #f1e3e3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3 ease;
}

button:hover {
  background-color: #0056b3;
}
p {
  color: #f1e3e3;
  font-size: 26px;
  margin-top: 20px;
}
footer p {
  font-size: 16px;
  font-style: italic;
  margin-top: 100px;
}
label {
  color: #f1e3e3;
  font-size: 18px;
}
